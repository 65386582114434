import * as React from "react"
import "../../styles/main.scss"
import Layout from "../../components/layout.js"
import Standings from "../../components/standings.js"

// markup
const IndexPage = () => {
    //const [selectedSeed, setSelectedSeed] = React.useState(seedList[0]);

    const [standings, setStandings] = React.useState([]);
    React.useEffect(() => {
        fetch(`https://us-central1-seedcycle-4bbf4.cloudfunctions.net/runs?seed=1331939780544881570`)
            .then(response => response.json())
            .then(data => {
                setStandings(data)
            });
    }, [])

    var unixTime = 1644969599; //Unix time for UTC 2022-02-15 11:59:59
    var d = new Date(unixTime * 1000);

    return (
        <main>
            <title>Seed Cycle - Admin Verify</title>
            <Layout></Layout>

            <div className="seed-summary">
                Admin Page for verification
            </div>

            {standings.map((run) => (
                <div key={run.Rank} className={`standing`}>
                    <div className="rank">
                        {run.Rank}
                    </div>
                    <div className="runner">
                        {run.SrcName}
                    </div>
                    <div className="time">
                        {run.InGameTime}
                    </div>
                </div>
            ))}
        </main>
    )
}

export default IndexPage
